import { DriverRegistrationButtonContainer } from './styles';

export function DriverRegistrationButton({ onOpenDriverModal }) {
  const DriverRegistrationUrl = "https://docs.google.com/forms/d/e/1FAIpQLSfjzBxP6bAKuLx0uQch9jk6Mi6-Zzz1DDOIXVgjr_A2_8xx-w/viewform"

  return (
    <DriverRegistrationButtonContainer onClick={() => window.open(DriverRegistrationUrl, '_blank')}>
      become a grunber driver
    </DriverRegistrationButtonContainer>
  );
}