import styled from 'styled-components'

export const PickupRegistrationButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  

  width: fit-content;
  padding: 14px 24px;

  background: var(--c-secondary-light);
  border-radius: 30px;
  border: 0;

  font-size: 11px;
  font-weight: 700;
  color: var(--c-white);
  text-transform: uppercase;

  transition: background 0.2s;

  &:hover {
    background: var(--c-primary);
  }

  @media(max-width: ${({ theme }) => theme.widths.screen}) {
    width: 100%;
  }
`