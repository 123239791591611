import { DriverRegistrationButton } from '../DriverRegistrationButton'

import becomeDriver from '../../assets/img-become-driver.svg';

import * as S from './styles'

export function BecomeDriverBox({ onOpenDriverModal }) {
  return (
    <S.Wrapper>
      <S.Container>
          <img src={becomeDriver} alt="Become Driver" />
          <S.BecomeDriverContent>
            <h1>
              Start your own “Scrap Metal”<br /><strong>Pickup Business</strong>
            </h1>
            <h2>Become a driver with GRUNBER.</h2>
            <p>
              Start increasing your revenue by working whenever you want, we offer a
              100% secure digital platform where you can make Scrap metal pickup all
              around Massachusetts. Apply TODAY...
            </p>
            <DriverRegistrationButton onOpenDriverModal={onOpenDriverModal} />
          </S.BecomeDriverContent>
      </S.Container>
    </S.Wrapper>
  );
}