import styled from 'styled-components'

export const FacebookButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 46px;
  height: 46px;

  background: var(--c-primary);
  border-radius: 50%;
  border: 0;

  transition: background 0.3s;

  svg {
    width: 8px;
    height: 16px;
    color: var(--c-secondary-light);
  }

  &:hover {
    background: var(--c-secondary-light);

    svg {
      color: var(--c-white);
    }
  }
`