import styled from "styled-components";

export const CommentSliderContainer = styled.div`
  display: flex;

  width: 100%;

  padding: 0px 10px;

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column;
  }
`