import styled from 'styled-components'
import { lighten } from 'polished'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper'
})`
  background: ${lighten(0.08, '#DEE7E7')};
`

export const Container = styled.div.attrs({
  className: 'content-container'
})`
  display: flex;

  @media(max-width: ${({ theme }) => theme.widths.screen}) {
    flex-direction: column;
  }

`

export const MaterialsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 30%;

  
  margin-top: 85px;
  margin-bottom: 90px;
  margin-right: 80px;
  
  @media(max-width: ${({ theme }) => theme.widths.screen}) {
    width: fit-content;
    margin-bottom: 0;
    margin-right: 0;
  }

  h1 {
    display: flex;
    font-size: 32px;
    font-weight: 300;
    color: var(--c-primary);
    flex-wrap: wrap;
    


    strong {
      width: max-content;
      position: relative;
      display: flex;

      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);
      height: 40px;

      z-index: 1;

      &::after {
        position: absolute;
        content: '';
        height: 8px;
        width: 100%;
        bottom: 5px;
        left: 0;
        background: var(--c-alert-primary);
        z-index: -1;
      }
    }
  }

  h2 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: var(--c-primary);
    letter-spacing: 1px;
  }

  p {
    margin-top: 11px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    line-height: 180%;
    letter-spacing: 1px;
  }

  /* >div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: 22px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      
      width: 60px;
      height: 60px;

      background: var(--c-white);
      border-radius: 50%;
      border: 0;
      box-shadow: 0px 4px 25px 5px rgba(33, 41, 47, 0.05);

      transition: background 0.5s;

      svg {
        width: 24px;
        height: 26px;

        color: var(--c-secondary-light)
      }

      & + button {
        margin-left: 16px;
      }

      &:hover {
        background: var(--c-secondary-light);

        svg {
          color: var(--c-white)
        }
      }
    }
  } */
`

export const MaterialsCards = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: ${({ theme }) => theme.widths.screen}) {
    display: grid;
    grid-template-columns: repeat(3, 240px);
    width: 100%;
    overflow: scroll;
    justify-content: stretch;
  }

  margin-top: 85px;
  margin-bottom: 90px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`