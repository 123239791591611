import { useState } from 'react'
import Modal from 'react-modal'
import 'react-datepicker/dist/react-datepicker.css'
import { MdClose } from 'react-icons/md'
import ReactTooltip from 'react-tooltip'

import grunberModalStep from '../../assets/img-grunber-modal-step-1.svg'
import infoIcon from '../../assets/info-icon.png'

import {
  SchedulePickupModalContainer,
  SchedulePickupModalContent,
  SchedulePickupModalHeaderContent,
  SchedulePickupModalFormContentStep,
} from './styles'

const pickupTypeInitialState = {
  easyLoad: false,
  basement: false,
  backupHelper: false,
}

const stripeLinks = {
  easyLoad: 'https://buy.stripe.com/5kAcMU4sSg5w2di005',
  basement: 'https://buy.stripe.com/eVa6owgbA2eG3hmdQW',
  backupHelper: 'https://buy.stripe.com/eVacMUbVk8D4f048wA',
}

export function SchedulePickupModal({ isOpen, onRequestClose }) {
  const [allowOpenLink, setAllowOpenLink] = useState(false)
  const [links] = useState(stripeLinks)
  const [pickupType, setPickupType] = useState(pickupTypeInitialState)

  async function handleCreateNewSchedulePickup(event) {
    event.preventDefault()
    if (!allowOpenLink) return

    let activeOption
    for (const type in pickupType) {
      pickupType[type] && (activeOption = type)
    }

    window.open(links[activeOption], '_blank').focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => {
          setAllowOpenLink(false)
          setPickupType(pickupTypeInitialState)

          onRequestClose()
        }}
        className="react-modal-close"
      >
        <MdClose />
      </button>

      <SchedulePickupModalContainer>
        <img src={grunberModalStep} alt="Grunber Modal" />
        <SchedulePickupModalContent>
          <SchedulePickupModalHeaderContent>
            <h1>Schedule a pickup</h1>
            <h2>Please provide your information below:</h2>
          </SchedulePickupModalHeaderContent>

          <SchedulePickupModalFormContentStep>
            <div className="material-input">
              <span>
                materials <b>*</b>
              </span>
              <textarea
                disabled
                placeholder="We charge a flat rate to remove all the scrap metal you have, all it must fit in one trip and the total load cannot exceed 2200 Lbs, which is the maximum load capacity of the vehicle.
                I am aware that each item weighs no more than 150 Lbs, otherwise, I am willing to help the driver load the items.
                All materials must be empty, without the presence of gases, oils or any type of liquid."
              />
            </div>

            <div className="types-input">
              <label>
                <span>
                  Pickup Tipe Rate Pickup Fee - We charge a flat rate and we
                  take all the metals you have <b>*</b>
                </span>

                {/* eslint-disable-next-line */}
                <a data-for="tooltip_pickup-modal" data-tip="React-tooltip">
                  <img
                    data-for="tooltip_pickup-modal"
                    src={infoIcon}
                    alt="informations"
                  />
                </a>
                <ReactTooltip
                  id="tooltip_pickup-modal"
                  effect="solid"
                  type="light"
                  place="left"
                  multiline={true}
                  className="tooltip-modal"
                >
                  <span>
                    What types of scrap metals can be recycled? <br />
                    <br />
                    Almost all metals can be recycled. In fact, most metals can
                    be recycled over and over again without altering their
                    properties. Some of the most popular metals that are
                    recycled are: <br />
                    <br />
                    At GRUNBER, we’re scrap metal removal experts. We know how
                    to handle all unwanted scrap and work with local recycling
                    facilities to ensure proper disposal of both metal and
                    non-metal components. Aluminum Brass Cast Iron Copper Steel
                    Tin Lead Zinc Light iron ( stove, refrigerator, boiler,
                    water heater, all metal furnitures). <br />
                    <br />
                    If you have any questions or concerns <br />
                    Please, contact our customer support team directly
                  </span>
                </ReactTooltip>
              </label>

              <div className="input-options-container">
                <div className="input-options">
                  <div>
                    <input
                      type="radio"
                      id="easy"
                      name="pickup-type"
                      value="easyLoad"
                      checked={pickupType.easyLoad}
                      onChange={(e) => {
                        setAllowOpenLink(true)
                        setPickupType({
                          easyLoad: e.target.value,
                          basement: false,
                          backupHelper: false,
                        })
                      }}
                    />
                    <label htmlFor="easy">
                      Easy load pickup - Flat rate $45.00(forty-five dollars)
                      I'm requesting 1 driver & 1 pickup truck. The items must
                      be all outside at the ground level, I agree to provide
                      help if my pickup driver cannot carry the item(s) alone.
                    </label>
                    <label htmlFor="easy" className="price">
                      $45.00
                    </label>
                  </div>

                  <br />

                  <div>
                    <input
                      type="radio"
                      id="basement"
                      name="pickup-type"
                      value="basement"
                      checked={pickupType.basement}
                      onChange={(e) => {
                        setAllowOpenLink(true)
                        setPickupType({
                          easyLoad: false,
                          basement: e.target.value,
                          backupHelper: false,
                        })
                      }}
                    />
                    <label htmlFor="basement">
                      Basement / floor / flight of stairs removal (Driver + your
                      help) Flat rate - $75.00(seventy-five dollars). I agree to
                      help the driver to remove and load the items.You will be
                      responsible for helping the driver to carry the items so
                      that the removal of the items is done carefully and very
                      carefully so that no accidents and damages occur. The
                      driver carries a loading cart with him to facilitate the
                      removal of materials.
                    </label>
                    <label htmlFor="basement" className="price">
                      $75.00
                    </label>
                  </div>

                  <br />

                  <div>
                    <input
                      type="radio"
                      id="backupHelper"
                      name="pickup-type"
                      value="backupHelper"
                      checked={pickupType.backupHelper}
                      onChange={(e) => {
                        setAllowOpenLink(true)
                        setPickupType({
                          easyLoad: false,
                          basement: false,
                          backupHelper: e.target.value,
                        })
                      }}
                    />
                    <label htmlFor="backupHelper">
                      Basement / floor / flight of stairs removal (Driver +
                      Backup helper) Flat rate - $130.00(one hundred and thirty
                      dollars). We will send you a driver and a Backup helper to
                      remove and load the materials with safety.
                    </label>
                    <label htmlFor="backupHelper" className="price">
                      $130.00
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </SchedulePickupModalFormContentStep>
          <div className="button-input">
            <button onClick={handleCreateNewSchedulePickup}>
              schedule now
            </button>
          </div>
        </SchedulePickupModalContent>
      </SchedulePickupModalContainer>
    </Modal>
  )
}
