import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.header.attrs({
  className: 'content-wrapper',
})`
  position: fixed;
  background: var(--c-white);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.03);
  z-index: 10;
  height: 78px;
`

export const Container = styled.div.attrs({
  className: 'content-container',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MobileMenu = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    padding: 5px 0px;

    background-color: var(--c-white);

    text-transform: uppercase;

    animation-name: ${({ isOpen }) => isOpen ? openAnimation : closeAnimation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    position: fixed;

    z-index: 9;

    width: 100%;
    height: 80px;

    & span {
      font-weight: bold;
      color: #00352f;
    }

    & > div {
      background-color: #45cb56;
      height: 1px;
      width: 100%;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    display: none;
  }
`

export const DriverButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;

  background: var(--c-white);
  margin-left: 32px;
  border: 0;

  font-size: 12px;
  font-weight: 700;
  color: var(--c-primary);
  text-transform: uppercase;

  img {
    margin: 0;
    margin-right: 9px;
  }
`

export const MobileButtonMenu = styled.button`
  display: none;
  background: none;
  border: none;
  grid-column-start: 12;
  height: fit-content;
  align-self: center;
  margin-top: 6px;

  svg {
    width: 2.5rem;
    fill: #2f4e4a;
  }

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    display: inline;
  }
`

const openAnimation = keyframes`
  0% { top: -5px; }
  100% { top: 76px; }
`

const closeAnimation = keyframes`
  0% { top: 76px; }
  100% { top: -5px; }
`
