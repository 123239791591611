import { FiMail, FiPhone } from 'react-icons/fi'

import { RoundedFacebookLinkButton } from '../RoundedFacebookLinkButton'
import { RoundedInstagramLinkButton } from '../RoundedInstagramLinkButton'

import logo from '../../assets/logo.svg';

import * as S from './styles'

export function Footer() {
  return (
    <S.Wrapper>
      <S.Container>
        <img src={logo} alt="Grunber Logo" />

        <S.ContactAndLinksContainer>
          <div className="contacts">
            <a href="mailto:grunberusa@gmail.com">
              <FiMail />
              grunberusa@gmail.com
            </a>
            <a href="tel:(617) 800-6746">
              <FiPhone />
              (617) 800-6746
            </a>
          </div>
          <S.LinksContent>
            <RoundedFacebookLinkButton />
            <RoundedInstagramLinkButton />
          </S.LinksContent>
        </S.ContactAndLinksContainer>
      </S.Container>
    </S.Wrapper>
  );
}