import { PickupRegistrationButtonContainer } from './styles';

export function PickupRegistrationButton({ onOpenSchedulePickupModal }) {
  // const pickupRegistrationUrl = "https://docs.google.com/forms/d/e/1FAIpQLScofmdGnqAtTk9mfB4EM-Fa2hDm1l57491M1cXy-yqJWMeUJA/viewform"

  return (
    // <PickupRegistrationButtonContainer onClick={() => window.open(pickupRegistrationUrl, '_blank')}>
    <PickupRegistrationButtonContainer onClick={() => onOpenSchedulePickupModal()}>
      schedule a pickup
    </PickupRegistrationButtonContainer>
  );
}