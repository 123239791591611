export const comments = [
  {
    authorType: 'Client',
    author: 'Alison A.',
    comment:
      'Came a day earlier than I expected! Great job. Will contact again when the need arises in the future.',
  },
  {
    authorType: 'Client',
    author: 'Renato F.',
    comment:
      'Fantastic company! Excellent customer service, efficient process.',
  },
  {
    authorType: 'Client',
    author: 'Kate N.',
    comment:
      'Leonardo and his crew were fabulous. I would hire them again in an instant. Excellent communication and very responsive. took pride in their work and worked quickly and effectively.',
  },
  {
    authorType: 'Client',
    author: 'Bruce H.',
    comment:
      'Great job, I will definitely be telling people about you company. Thank you, Bruce.',
  },
]
