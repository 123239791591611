import { FaCheck } from 'react-icons/fa'

import {
  StepCardContainer,
  StepCardContentHeader,
  StepCardBorderIcon,
  StepCardIcon,
  StepCardDottedLine,
  StepCardContent
} from './styles'

export function StepCard({
  pathIcon, 
  nameIcon, 
  numberStep, 
  title, 
  description, 
  finishStep = false
}) {
  return (
    <StepCardContainer>
      <StepCardContentHeader>
        <StepCardBorderIcon>
          <StepCardIcon>
            <img src={pathIcon} alt={nameIcon} />
          </StepCardIcon>
        </StepCardBorderIcon>

        <StepCardDottedLine />

        {finishStep &&
          <div className="finish-step">
            <div>
              <FaCheck color="#45CB56"/>
            </div>
          </div>
        }
      </StepCardContentHeader>

      <StepCardContent>
        <div>
          <div>
            <span>{`Step ${numberStep}`}</span>
          </div>
        </div>

        <h1>{title}</h1>
        <p>{description}</p>
      </StepCardContent>
    </StepCardContainer>
  );
}