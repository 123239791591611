import { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import { CommentCard } from '../CommentCard'

import { CommentSliderContainer } from './styles'

import { widths } from '../../styles/theme'
import { comments } from './comments'

export class SimpleSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 8000,
      beforeChange: (prev, next) => {
        const windowWidth = parseInt(widths.tablet.slice(0, -2), 10)

        if (window.innerWidth < windowWidth)
          return this.setState({ currentSlide: next })

        //
        let nextPos = next - 1
        if (next - 1 === 3) nextPos = 2
        if (next - 1 === -1) nextPos = 0
        this.setState({ currentSlide: nextPos })
      },
      customPaging: (i) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '18px',
            height: '18px',
            border: '1px solid #00352F',
            borderRadius: '50%',
          }}
        >
          <div
            style={{
              width: '10px',
              height: '10px',
              background: this.state.currentSlide === i ? '#00352F' : '#FFFFFF',
              borderRadius: '50%',
            }}
          />
        </div>
      ),
      responsive: [
        {
          breakpoint: 768, // widths.tablet
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }
    return (
      <Slider {...settings}>
        {comments.map((comment, index) => (
          <CommentSliderContainer key={index}>
            <CommentCard
              comment={comment.comment}
              author={comment.author}
              authorType={comment.authorType}
            />
          </CommentSliderContainer>
        ))}
      </Slider>
    )
  }
}
