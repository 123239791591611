import styled from 'styled-components'
import { lighten } from 'polished'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper'
})`
  background: ${lighten(0.08, '#DEE7E7')};
`

export const Container = styled.div.attrs({
  className: 'content-container'
})`
  display: flex;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column-reverse;
  }

  img {
    margin: 110px 55px 75px 0;

    @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      margin: 0 0 75px 0
    }
  }
`

export const FinishBoxContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 110px 0 105px 0;

  h1 {
    font-size: 32px;
    font-weight: 300;
    color: var(--c-primary);

    strong {
      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 22px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    letter-spacing: 1px;
    line-height: 180%;

    & + p {
      margin-top: 0px;
    }
  }
`

export const FinishBoxContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
`

export const FinishBoxContentFacebookButton = styled.div`
  display: flex;
  align-items: center;

  margin-left: 32px;

  div {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    @media(max-width: ${({ theme }) => theme.widths.screen}) {
      display: none;
    }

    strong {
      font-size: 14px;
      font-weight: 700;
      color: var(--c-primary);
    }

    span  {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: var(--c-gray-primary);
    }
  }
`