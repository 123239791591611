import styled from 'styled-components'

export const CommentCardContainer = styled.div`
  display: flex;

  width: 100%;
  margin: 10px;
`

export const CommentCardQuoteBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 96px;
  height: 96px;

  border: 1px solid var(--c-secondary-light);
  border-radius: 10px;
`

export const CommentCardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  margin-left: 37px;

  p {
    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    line-height: 180%;
    letter-spacing: 1px;
  }

  strong {
    margin-top: 30px;

    font-size: 14px;
    font-weight: 700;
    color: var(--c-primary);
    letter-spacing: 1px;
  }

  span {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    letter-spacing: 1px;
  }
`