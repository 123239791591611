import { FaFacebookF } from 'react-icons/fa'

import { FacebookButton } from './styles';

export function RoundedFacebookLinkButton() {
  const facebookUrl = "https://web.facebook.com/grunberoficial"

  return (
    <FacebookButton onClick={() => window.open(facebookUrl, '_black')}>
      <FaFacebookF />
    </FacebookButton>
  );
}