import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper'
})`


`

export const Container = styled.div.attrs({
  className: 'content-container'
})`

`

export const StepsBoxContent = styled.h1`

  margin-top: 65px;
  margin-bottom: 35px;

  font-size: 32px;
  font-weight: 300;
  color: var(--c-primary);
  strong {
    width: max-content;
    position: relative;
    display: flex;

    font-size: 32px;
    font-weight: 800;
    color: var(--c-primary);

    b {
      width: max-content;
      position: relative;
      display: flex;

      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);

      z-index: 1;
      
      &::after {
      position: absolute;

      content: '';
      
      height: 8px;
      width: 100%;
      
      bottom: 5px;
      left: 0;
      background: var(--c-alert-primary);

      z-index: -1;
    }
    }
  }
`

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column;
  };

`

export const StepsContentBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 60px;

  &:not(:last-child) {
    margin-right: 40px;
  }
`