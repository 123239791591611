import logo from '../../assets/logo.svg';
import driver from '../../assets/icon-driver.svg';
import { ReactComponent as Menu } from '../../assets/icon-menu.svg';
import { PickupRegistrationButton } from '../PickupRegistrationButton';
import { useState } from 'react'

import * as S from './styles'


export function Header({ onOpenSchedulePickupModal }) {
  const [isOpen, setIsOpen] = useState(false)

  const openLink = () => {
    const DriverRegistrationUrl = 
      "https://docs.google.com/forms/d/e/1FAIpQLSfjzBxP6bAKuLx0uQch9jk6Mi6-Zzz1DDOIXVgjr_A2_8xx-w/viewform"

    window.open(DriverRegistrationUrl, '_blank')
  }

  return (
    <>
        <S.MobileMenu isOpen={isOpen}>
          <span onClick={onOpenSchedulePickupModal}>schedule a pickup</span>

          <div />

          <span onClick={openLink}>become a grunber driver</span>
        </S.MobileMenu>

      <S.Wrapper>
        <S.Container>
          <img src={logo} alt="Grunber Logo" />

          <S.ButtonsContainer>
            <PickupRegistrationButton
              onOpenSchedulePickupModal={onOpenSchedulePickupModal}
            />
            <S.DriverButton onClick={openLink}>
              <img src={driver} alt="Driver Logo" />
              become a grunber driver
            </S.DriverButton>
          </S.ButtonsContainer>

          <S.MobileButtonMenu onClick={() => setIsOpen((current) => !current)}>
            <Menu />
          </S.MobileButtonMenu>
        </S.Container>
      </S.Wrapper>
    </>
  )
}
