import { 
  MaterialCardContainer
 } from './styles';

export function MaterialCard({icon, title, commingSoon, disabledCard}) {
  return (
    <MaterialCardContainer disabled={disabledCard}>
      <div>
        {commingSoon && (
          <span>coming soon</span>
        )}
        <img src={icon} alt="Scrap Metal" />
      </div>
      <h1>{title}</h1>
    </MaterialCardContainer>
  );
}