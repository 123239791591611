import { PickupRegistrationButton } from '../PickupRegistrationButton'
import { RoundedFacebookLinkButton } from '../RoundedFacebookLinkButton'

import mobileApp from '../../assets/img-mobile-app.svg';

import * as S from './styles'

export function FinishBox({ onOpenSchedulePickupModal }) {
  return (
    <S.Wrapper>
      <S.Container>
          <img src={mobileApp} alt="Mobile App" />
          <S.FinishBoxContent>
            <h1>
              We will soon be launching a<br /><strong>100% digital mobile app</strong>
            </h1>
            <p>
              For your greatest convenience we will launch an application in which you can
              request a pickup directly from your cell phone wherever you are.
            </p>
            <p>
              You just inform the pickup address and we are responsible to find a local
              driver near your address.
            </p>
            <p>
              But while we're still working on our app, you can register a pickup schedule
              through our website.
            </p>

            <S.FinishBoxContentButtons>
              <PickupRegistrationButton onOpenSchedulePickupModal={onOpenSchedulePickupModal} />

              <S.FinishBoxContentFacebookButton>
                <RoundedFacebookLinkButton />
                <div>
                  <strong>Questions? Contact Us</strong>
                  <span>We speak English, Spanish and Portuguese.</span>
                </div>
              </S.FinishBoxContentFacebookButton>
            </S.FinishBoxContentButtons>
          </S.FinishBoxContent>
      </S.Container>
    </S.Wrapper>
  );
}