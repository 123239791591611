import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper',
})``

export const Container = styled.div.attrs({
  className: 'content-container',
})`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 90%;
`

export const CommentsContainerSlider = styled.div``

export const CustomerCommentsHeaderContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  grid-area: auto / 1 / auto / 6;

  margin-top: 65px;
  margin-bottom: 65px;

  h1 {
    font-size: 16px;
    font-weight: 700;
    color: var(--c-primary);
    text-transform: uppercase;

    strong {
      color: var(--c-secondary-light);
    }
  }
`

export const CustomerCommentsContentSlider = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  grid-area: auto / 1 / auto / 13;

  margin-bottom: 130px;
`
