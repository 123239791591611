import styled from 'styled-components'
import {lighten} from 'polished'

export const MaterialCardContainer = styled.div`
  width: 220px;
  height: 260px;

  padding: 10px;

  background: var(--c-white);
  border-radius: 10px;

  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.03);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;

    position: relative;

    margin-bottom: 15px;

    background: ${lighten(0.05, "#DEE7E7")};
    border-radius: 10px;

    z-index: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      position: absolute;

      top: 0;
      right: 0;

      margin: 8px 8px 0 0;
      padding: 5px 10px;
      background: var(--c-gray-secondary);
      border-radius: 30px;

      font-size: 10px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      z-index: 1;
    }
  }

  h1 {
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.disabled
      ? "var(--c-gray-primary-light)"
      : "var(--c-primary)"};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    margin-left: 14px;
  }
`