import { StepCard } from '../StepCard'

import recycle from '../../assets/icon-recycle.svg';
import mapPoint from '../../assets/icon-map-point.svg';
import money from '../../assets/icon-money.svg';

import * as S from './styles'

export function StepsBox() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.StepsBoxContent>
          How can I<br />
          <strong><b>Schedule</b>&nbsp;a Pickup?</strong>
        </S.StepsBoxContent>

        <S.StepsContainer>
          <S.StepsContentBox>
            <StepCard
              pathIcon={recycle}
              nameIcon="Recycle"
              numberStep="1"
              title="What metal do you have?"
              description="Please, inform what type of scrap metal you 
          have, quantity and estimate weight."
            />
          </S.StepsContentBox>
          <S.StepsContentBox>
            <StepCard
              pathIcon={mapPoint}
              nameIcon="Map Point"
              numberStep="2"
              title="Where will the scrap be?"
              description="In an easy load area (must be outside in the 
            ground level) or need to be removed from the 
            basement/floors/stairs?"
            />
          </S.StepsContentBox>
          <S.StepsContentBox>
            <StepCard
              pathIcon={money}
              nameIcon="Money"
              numberStep="3"
              title="Rate Scrap metal Hauling"
              description="We charge an affordable fee for pickup, it helps 
            the driver to increase their revenues and keep 
            them doing this as a full time job delivering you 
            and good experience."
              finishStep
            />
          </S.StepsContentBox>
        </S.StepsContainer>

      </S.Container>
    </S.Wrapper>
  );
}