import styled from 'styled-components'

import recycling from '../../assets/img-schedule-recycling.png'
import recyclingMobile from '../../assets/img-schedule-recycling-mobile.png'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper',
})`
  background-color: var(--c-white);
  background-image: url(${recycling});
  background-repeat: no-repeat;
  background-size: 50vw;
  background-position: right;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    background-position: bottom;
    background-size: 100%;
    padding-bottom: 80%;    

    background-image: url(${recyclingMobile});
    background-position-x: 10px;
  }

`

export const Container = styled.div.attrs({
  className: 'content-container',
})`
  display: flex;
  align-items: center;

  width: 90%;

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column;
  }
`

export const ScheduleRecyclingContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 40%;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    width: 100%;
  }

  margin: 100px 0;

  h1 {
    font-size: 32px;
    font-weight: 300;
    color: var(--c-primary);

    strong {
      width: max-content;
      position: relative;
      display: flex;

      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);

      z-index: 1;

      &::after {
        position: absolute;

        content: '';
        
        height: 8px;
        width: 100%;
        
        bottom: 5px;
        left: 0;
        background: var(--c-alert-primary);

        z-index: -1;
      }
    }
  }

  p {
    margin-top: 21px;
    margin-bottom: 35px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    letter-spacing: 1px;
    line-height: 180%;
  }
`

export const ScheduleRecyclingContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ScheduleRecyclingContentFacebookButton = styled.div`
  display: flex;
  align-items: center;

  margin-left: 32px;

  div {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    strong {
      font-size: 14px;
      font-weight: 700;
      color: var(--c-primary);
    }

    span  {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: var(--c-gray-primary);
    }
  }
`

export const Questions = styled.div`
  @media (max-width: ${({ theme }) => theme.widths.screen}) {
    display: none !important;
  }
`
