import quote from '../../assets/icon-quote.svg';

import { 
  CommentCardContainer,
  CommentCardQuoteBox,
  CommentCardContent
} from './styles';

export function CommentCard({
  positionCard, 
  comment, 
  author, 
  authorType
}) {
  return (
    <CommentCardContainer position={positionCard} >
      <CommentCardQuoteBox>
        <img src={quote} alt="Quote"/>
      </CommentCardQuoteBox>
      <CommentCardContent>
        <p>
        {comment}
        </p>

        <strong>{author}</strong>
        <span>{authorType}</span>
      </CommentCardContent>
    </CommentCardContainer>
  );
}