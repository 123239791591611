import { PickupRegistrationButton } from '../PickupRegistrationButton'
import { Questions } from '../Questions'
import { RoundedFacebookLinkButton } from '../RoundedFacebookLinkButton'

import * as S from './styles'

export function ScheduleRecyclingBox({ onOpenSchedulePickupModal }) {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ScheduleRecyclingContent>
          <h1>
            Start an new
            <br />
            <strong>Schedule Recycling</strong>
          </h1>
          <p>
            Our means of communication is 100% digital, you only inform us the
            quantity and the type of recyclable, the responsibility is ours to
            make the collection without you having to leave the comfort of your
            home.
          </p>

          <S.ScheduleRecyclingContentButtons>
            <PickupRegistrationButton
              onOpenSchedulePickupModal={onOpenSchedulePickupModal}
            />

            <S.ScheduleRecyclingContentFacebookButton>
              <RoundedFacebookLinkButton />
              <Questions />
            </S.ScheduleRecyclingContentFacebookButton>
          </S.ScheduleRecyclingContentButtons>
        </S.ScheduleRecyclingContent>
      </S.Container>
    </S.Wrapper>
  )
}
