import styled from 'styled-components'
import { lighten } from 'polished'

export const StepCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StepCardContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  
  width: 100%;

  margin-bottom: 20px;

  .finish-step {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 36px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      
      width: 1.875rem;
      height: 1.875rem;

      background: ${lighten(0.35, '#45CB56')};
      border-radius: 50%;

      svg {
        width: 0.9rem;
      }
    }
  }
`

export const StepCardBorderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 80px;
  height: 80px;

  border-radius: 50%;
  border: 1px solid var(--c-gray-secondary);
`

export const StepCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 70px;
  height: 70px;
  
  border-radius: 50%;
  background: ${lighten(0.05, "#DEE7E7")};

  img {
    width: 38px;
    height: 38px;
  }
`

export const StepCardDottedLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin-left: 36px;

  border: 5px solid var(--c-gray-secondary);
  border-style: dotted none none none;
`

export const StepCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    margin-bottom: 8px;

    >div {
      width: 70px;
      height: 30px;

      border-radius: 1.875rem;
      background: var(--c-white);
      border: 1px solid var(--c-secondary-light);;

      span {
        font-size: 11px;
        font-weight: 900;
        color: var(--c-secondary-light);

        text-transform: uppercase;
      }
    }
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    color: var(--c-primary);
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    line-height: 180%;
    letter-spacing: 1px;
  }
`