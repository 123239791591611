import styled from 'styled-components'
import { lighten } from 'polished'
import { widths } from '../../styles/theme'

export const SchedulePickupModalContainer = styled.form`
  display: flex;
  max-width: ${widths.screen};

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    & > img {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.widths.mobile}) {
    max-height: 90vh;
    flex-direction: column;
  }

  .button-input {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 25px 0;

    button {
      width: 100%;
      height: 46px;

      background: transparent;
      border: 1px solid var(--c-gray-primary-light);
      border-radius: 5px;
      padding: 14px 24px;

      font-size: 12px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      transition: background 0.3s, color 0.3s, border 0.3s;

      &:hover {
        border: 0;
        background: var(--c-secondary-light);
        color: var(--c-white);
      }

      & + button {
        border: 0;
        background: var(--c-secondary-light);
        color: var(--c-white);

        margin-left: 38px;

        &:hover {
          background: ${lighten(0.2, '#45CB56')};
        }
      }
    }
  }
`

export const SchedulePickupModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 0px 34px 0 14px;

  .next-step-button {
    width: 100%;

    margin-top: 30px;
    padding: 14px 24px;

    background: transparent;
    border: 1px solid var(--c-secondary-light);
    border-radius: 5px;

    font-size: 12px;
    font-weight: 800;
    color: var(--c-secondary-light);
    text-transform: uppercase;

    transition: background 0.3s, color 0.3s, border 0.3s;

    &:hover {
      border: 0;
      background: var(--c-secondary-light);
      color: var(--c-white);
    }
  }

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    margin: 0px 15px;
  }
`

export const SchedulePickupModalHeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 50px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--c-primary);
  }

  h2 {
    margin-top: 5px;

    font-size: 12px;
    font-weight: 400;
    color: var(--c-gray-primary);
  }

  @media (max-width: ${({ theme }) => theme.widths.small}) {
    margin-top: 60px;
  }
`

export const SchedulePickupModalStepsContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 30px;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    font-size: 12px;
    font-weight: 800;
    color: ${(props) =>
      props.stepUser === 1
        ? 'var(--c-primary)'
        : 'var(--c-gray-primary-light)'};
    text-decoration: underline;
    text-transform: uppercase;

    & + h1 {
      margin-left: 30px;
      color: ${(props) =>
        props.stepUser === 2
          ? 'var(--c-primary)'
          : 'var(--c-gray-primary-light)'};
    }
  }
`

export const SchedulePickupModalDottedLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin-left: 16px;

  border: 5px solid var(--c-gray-secondary);
  border-style: dotted none none none;
`

export const SchedulePickupModalFormContentStep = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;

  margin-top: 30px;

  .material-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    > span {
      font-size: 10px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      b {
        font-size: 10px;
        font-weight: 800;
        color: var(--c-error-primary);
        text-transform: uppercase;
      }
    }

    textarea {
      width: 100%;
      height: 130px;

      margin-top: 8px;
      padding: 20px;

      border: 1px solid var(--c-gray-secondary);
      border-radius: 5px;

      outline-color: ${lighten(0.3, '#45CB56')};

      font-size: 12px;
      font-weight: 600;
      color: var(--c-gray-primary);
      letter-spacing: 1px;

      resize: none;

      ::placeholder {
        font-size: 12px;
        font-weight: 400;
        color: ${lighten(0.2, '#819C9B')};
        letter-spacing: 1px;
      }

      @media (max-width: ${({ theme }) => theme.widths.mobile}) {
        height: 100px;
      }

      @media (max-width: ${({ theme }) => theme.widths.small}) {
        height: 80px;
      }
    }
  }

  .input-options-container {
    @media (max-width: ${({ theme }) => theme.widths.mobile}) {
      display: flex;
      height: 200px;
      overflow: hidden;
    }

    @media (max-width: ${({ theme }) => theme.widths.small}) {
      height: 130px;
    }
  }

  .types-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    margin-top: 20px;

    width: 100%;

    & > label {
      width: 100%;

      display: flex;
      justify-content: space-between;

      margin-bottom: 5px;

      & img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 10px;
        font-weight: 800;
        color: var(--c-primary);
        text-transform: uppercase;

        margin-bottom: 8px;

        b {
          font-size: 10px;
          font-weight: 800;
          color: var(--c-error-primary);
          text-transform: uppercase;
        }
      }
    }

    .input-options {
      @media (max-width: ${({ theme }) => theme.widths.mobile}) {
        padding: 10px 0px;
        width: 100%;
        max-height: 220px;
        overflow-y: scroll;
      }
    }

    .input-options > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      width: 100%;
      padding: 10px;

      background: ${lighten(0.05, '#DEE7E7')};
      border-radius: 5px;

      pointer-events: all;

      & > input {
        margin-right: 7px;
        max-width: 20px;

        @media (max-width: ${({ theme }) => theme.widths.mobile}) {
          min-width: 20px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      > label {
        font-size: 12px;
        font-weight: 400;
        color: var(--c-gray-primary);
        letter-spacing: 0.03em;

        &:hover {
          cursor: pointer;
        }
      }

      & > .price {
        font-weight: bold;
        font-size: 11px;
        color: var(--c-primary);
        margin-left: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: ${({ theme }) => theme.widths.mobile}) {
      justify-content: flex-start;
      overflow: hidden;
    }
  }

  @media (max-width: ${({ theme }) => theme.widths.small}) {
    margin-top: 10px;
  }
`
