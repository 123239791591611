import styled from 'styled-components'
import {lighten} from 'polished'

export const SchedulePickupModalContainer = styled.form`
  display: flex;
`

export const SchedulePickupModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 0px 34px 0 14px;

  .next-step-button {
    width: 100%;

    margin-top: 30px;
    padding: 14px 24px;

    background: transparent;
    border: 1px solid var(--c-secondary-light);
    border-radius: 5px;
    
    font-size: 12px;
    font-weight: 800;
    color: var(--c-secondary-light);
    text-transform: uppercase;

    transition: background 0.3s, color 0.3s, border 0.3s;

    &:hover {
      border: 0;
      background: var(--c-secondary-light);
      color: var(--c-white);
    }
  }

  .button-input {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 25px 0;

    button {
      width: 100%;
      height: 46px;

      background: transparent;
      border: 1px solid var(--c-gray-primary-light);
      border-radius: 5px;
      padding: 14px 24px;
      
      font-size: 12px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      transition: background 0.3s, color 0.3s, border 0.3s;

      &:hover {
        border: 0;
        background: var(--c-secondary-light);
        color: var(--c-white);
      }

      & + button {
        border: 0;
        background: var(--c-secondary-light);
        color: var(--c-white);

        margin-left: 38px;

        &:hover {
          background: ${lighten(0.2, '#45CB56')}
        }
      }
    }
  }
`

export const SchedulePickupModalHeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 50px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--c-primary);
  }

  h2 {
    margin-top: 5px;

    font-size: 12px;
    font-weight: 400;
    color: var(--c-gray-primary);
  }
`

export const SchedulePickupModalStepsContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 30px;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    font-size: 12px;
    font-weight: 800;
    color: ${props => props.stepUser === 1 ? "var(--c-primary)" : "var(--c-gray-primary-light)"};
    text-decoration: underline;
    text-transform: uppercase;

    & + h1 {
      margin-left: 30px;
      color: ${props => props.stepUser === 2 ? "var(--c-primary)" : "var(--c-gray-primary-light)"};
    }
  }
`

export const SchedulePickupModalDottedLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin-left: 16px;

  border: 5px solid var(--c-gray-secondary);
  border-style: dotted none none none;
`

export const SchedulePickupModalFormContentStep1 = styled.div`
  display: grid;

  width: 100%;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 35px;

  margin-top: 30px;

  >div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    >span {
      font-size: 10px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      b {
        font-size: 10px;
        font-weight: 800;
        color: var(--c-error-primary);
        text-transform: uppercase;
      }
    }

    >input {
      width: 250px;
      height: 46px;

      margin-top: 8px;
      padding: 20px;

      border: 1px solid var(--c-gray-secondary);
      border-radius: 5px;

      outline-color: ${lighten(0.3, "#45CB56")};

      font-size: 12px;
      font-weight: 600;
      color: var(--c-gray-primary);
      letter-spacing: 1px;
    }
  }
`

export const SchedulePickupModalFormContentStep2 = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;

  margin-top: 30px;

  .zip-and-vehicle-container {
    display: grid;

    width: 100%;

    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;

    .zip-code-input, .vehicle-plate-input {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      width: 100%;

      >span {
        font-size: 10px;
        font-weight: 800;
        color: var(--c-primary);
        text-transform: uppercase;

        b {
          font-size: 10px;
          font-weight: 800;
          color: var(--c-error-primary);
          text-transform: uppercase;
        }
      }

      >div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        width: 100%;

        margin-top: 8px;

        input {
          width: 100%;
          height: 46px;
          position: relative;

          padding: 20px 20px 20px 55px;

          border: 1px solid var(--c-gray-secondary);
          border-radius: 5px;

          outline-color: ${lighten(0.3, "#45CB56")};

          font-size: 12px;
          font-weight: 600;
          color: var(--c-gray-primary);
          letter-spacing: 1px;

          z-index: 0;
        }

        >svg {
          width: 20px;
          height: 20px;
          position: absolute;

          margin-left: 20px;

          color: var(--c-gray-primary);

          z-index: 1;
        }
      }
    }
  }

  .upload-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    >span {
      margin-top: 25px;

      font-size: 10px;
      font-weight: 800;
      color: var(--c-primary);
      text-transform: uppercase;

      b {
        font-size: 10px;
        font-weight: 800;
        color: var(--c-error-primary);
        text-transform: uppercase;
      }
    }

    >div {
      display: flex;
      align-items: center;

      width: 100%;
      height: 46px;

      margin-top: 8px;
      padding: 20px 7px 20px 20px;

      border: 1px solid var(--c-gray-secondary);
      border-radius: 5px;

      >svg {
        width: 20px;
        height: 20px;
        color: ${props => props.imageUploaded 
          ? "var(--c-primary)" 
          : "var(--c-gray-primary)"
        };
      }
      
      >span {
        margin-left: 15px;
        
        font-size: 12px;
        font-weight: 600;
        color: var(--c-gray-primary);
        letter-spacing: 1px;
      }

      >label {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: auto;

        width: 32px;
        height: 32px;

        border: 1px solid var(--c-gray-primary-light);
        border-radius: 5px;

        color: var(--c-gray-primary-light);

        transition: border 0.2s, color 0.2s;

        :hover {
          border: 1px solid var(--c-primary);
          color: var(--c-primary);
        }
      }
    }
  }
`

export const SchedulePickupModalFormContentStep3 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  margin: 64px 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background: ${lighten(0.4, '#45CB56')};
    border-radius: 50%50%;

    svg {
      width: 30px;
      height: 30px;
      color: var(--c-secondary-light)
    }
  }

  h1 {
    width: 500px;
    margin-top: 16px;

    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: 1px;
    color: var(--c-primary);
  }

  strong {
    width: 500px;
    margin-top: 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: 1px;
    color: var(--c-primary);
  }

  p {
    width: 500px;
    margin-top: 12px;

    font-size: 12px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: 1px;
    color: var(--c-gray-primary);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 538px;
    height: 46px;

    margin-top: 30px;
    padding: 14px 24px;

    background: transparent;
    border: 1px solid var(--c-gray-primary-light);
    border-radius: 5px;

    font-size: 12px;
    font-weight: 700;
    line-height: 18.12px;
    letter-spacing: 1px;
    color: var(--c-primary);

    transition: background 0.2s, color 0.2s, border 0.2s;

    &:hover {
      border: 0;
      background: var(--c-secondary-light);
      color: var(--c-white);
    }
  }
`

