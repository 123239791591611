import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper'
})``

export const Container = styled.div.attrs({
  className: 'content-container'
})`
  display: flex;
  justify-content: space-between;
  background: var(--c-white);

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 55px 0;
  }

  img {
    margin: 65px 0;

    @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      width: 180px;
      margin-bottom: 15px !important;
      margin: 0;
    }
  }
`

export const ContactAndLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    align-items: start;

    @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      align-items: center;
    }

    & > :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-primary-light);

    svg {
      width: 20px;
      height: 20px;
      margin-top: 1px;
      margin-right: 10px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-primary-light);
    text-decoration: none;

    svg {
      width: 20px;
      height: 20px;
      margin-top: 1px;
      margin-right: 10px;
    }
  }
`

export const LinksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 16px;
  margin-left: 30px;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    margin-top: 18px;
    margin-left: 0;
  }
`