import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'

import grunberModalStep from '../../assets/img-grunber-modal-step-1.svg'
import TickIcon from '../../assets/tick.svg'

import {
  SchedulePickupModalContainer,
  SchedulePickupModalContent,
  SchedulePickupModalHeaderContent,
} from './styles'

export function ConfirmationModal({ isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <MdClose />
      </button>

      <SchedulePickupModalContainer>
        <img src={grunberModalStep} alt="Grunber Modal" />
        <SchedulePickupModalContent>

          <SchedulePickupModalHeaderContent>
            <img src={TickIcon}  alt="Tick icon" />

            <h1>Your pickup schedule is confirmed!</h1>
            <h2>Thank you for choosing GRUNBER.</h2>

            <br />

            <span>
              Soon we send the driver information that will make your
              collection.
            </span>
            <div className="button-input">
              <button onClick={onRequestClose}>
                OK
              </button>
            </div>
          </SchedulePickupModalHeaderContent>
        </SchedulePickupModalContent>
      </SchedulePickupModalContainer>
    </Modal>
  )
}
