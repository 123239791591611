import styled from 'styled-components'
import aboutUs from '../../assets/img-about-us.png'
import aboutUsMobile from '../../assets/img-about-us-mobile.png'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper',
})`
  background-color: var(--c-white);
  background-color: var(--c-white);
  background-image: url(${aboutUs});
  background-repeat: no-repeat;
  background-size: 50vw;
  background-position: right;
  
  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    background-position: bottom;
    background-size: 100%;
    padding-bottom: 80%;
    background-image: url(${aboutUsMobile});
    background-position-x: 10px;
  }
`

export const Container = styled.div.attrs({
  className: 'content-container',
})`
  display: flex;
  align-items: center;

  width: 90%;

  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    flex-direction: column;
    padding-bottom: 50px;
  }
`

export const AboutUsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 40%;


  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    width: 100%;
  }

  margin: 100px 0;

  h1 {
    display: flex;
    font-size: 32px;
    font-weight: 300;
    color: var(--c-primary);

    strong {
      width: max-content;
      position: relative;
      display: flex;

      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);

      z-index: 1;

      &::after {
        position: absolute;

        content: '';
        
        height: 8px;
        width: 100%;
        
        bottom: 5px;
        left: 0;
        background: var(--c-alert-primary);

        z-index: -1;
      }
    }
  }

  p {
    margin-top: 22px;
    margin-bottom: 25px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    letter-spacing: 1px;
    line-height: 180%;

    & + p {
      margin-top: 0px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: max-content;

    button {
      & + button{
        margin-left: 16px;
      }
    }
  }
`

export const ImageContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    margin-top: -50px;
  }
`

export const Image = styled.img`
  @media (max-width: ${({ theme }) => theme.widths.tablet}) {
    width: 170%;

    margin: 0px -50% 0px 0px;
  }
`
