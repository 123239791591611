import React from 'react'
import * as S from './styles'

export function Questions() {
  return (
    <S.Questions>
      <strong>Questions? Contact Us</strong>
      <span>
        We speak English, Spanish <br />
        and Portuguese.
      </span>
    </S.Questions>
  )
}
