import styled from 'styled-components'
import {lighten} from 'polished'

import introduction from '../../assets/img-introduction-box.png'
import introductionMobile from '../../assets/img-introduction-box-mobile.png'

export const Wrapper = styled.div.attrs({ 
  className: 'content-wrapper' 
})`
  background-color: ${lighten(0.08, '#DEE7E7')};
  background-image: url(${introduction});
  background-repeat: no-repeat;
  background-size: 50vw;
  background-position: right;

  padding: 160px 0 100px 0;

  img {
    margin-top: 120px;
    width: 800px;
    height: 500px;
    object-fit: contain;
  }

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
    background-position: bottom;
    background-size: 95%;
    padding-bottom: 80%;    

    background-image: url(${introductionMobile});
    background-position-x: 30px;
  }
`

export const Container = styled.div.attrs({ 
  className: 'content-container' 
})`
  display: flex;
  position: relative;
`

export const IntroductionContent = styled.div`
  display: flex;
  flex-direction: column;


  h2 {
    font-size: 16px;
    font-weight: 700;
    color: var(--c-primary);
    text-transform: uppercase;
  }

  h1 {
    margin-top: 1px;
    
    font-size: 40px;
    font-weight: 800;
    color: var(--c-primary);

    strong {
      position: relative;
      font-size: 40px;
      font-weight: 800;
      color: var(--c-primary);

      z-index: 1;

      &::after {
        position: absolute;

        content: '';
        
        height: 8px;
        width: 100%;
        
        bottom: 5px;
        left: 0;
        background: var(--c-alert-primary);

        z-index: -1;
      }
    }
  }

  p {
    margin-top: 7px;
    margin-bottom: 30px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    line-height: 180%;
  }
`

export const IntroductionContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

`

export const IntroductionContentFacebookButton = styled.div`
  display: flex;
  align-items: center;

  margin-left: 32px;

  div {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    @media(max-width: ${({ theme }) => theme.widths.tablet }) {
      display: none;
    }

    strong {
      font-size: 14px;
      font-weight: 700;
      color: var(--c-primary);
    }

    span  {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: var(--c-gray-primary);
    }
  }
`