// import {
//   MdKeyboardArrowLeft,
//   MdKeyboardArrowRight
// } from 'react-icons/md'

import scrapMetal from '../../assets/icon-scrap-metal.svg';
import furniture from '../../assets/icon-furniture.svg';
import refundable from '../../assets/icon-refundable-bottles.svg';

import { MaterialCard } from '../MaterialCard'

import * as S from './styles'

export function MaterialsBox() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.MaterialsContent>
          <h1>
            What can I&nbsp;<strong>Dispose?</strong>
          </h1>
          <h2>Take a look at all the material types we pickup.</h2>
          <p>
            With your help, we've diverted millions of pounds
            of valuable resources from landfills all over the
            world, and we're just getting started.
          </p>

          {/* <div>
          <button>
            <MdKeyboardArrowLeft />
          </button>
          <button>
            <MdKeyboardArrowRight />
          </button>
        </div> */}
        </S.MaterialsContent>
        <S.MaterialsCards>
          <MaterialCard icon={scrapMetal} title="Scrap Metal" />
          <MaterialCard icon={furniture} title="Furniture" commingSoon disabledCard />
          <MaterialCard icon={refundable} title="Refundable Cans & Bottles" commingSoon disabledCard />
        </S.MaterialsCards>
      </S.Container>
    </S.Wrapper>
  );
}