import styled from 'styled-components'
import {lighten} from 'polished'

export const Wrapper = styled.div.attrs({
  className: 'content-wrapper'
})`
  background: ${lighten(0.08, '#DEE7E7')};

`


export const Container = styled.div.attrs({
  className: 'content-container'
})`

  display: flex;

  @media(max-width: ${({ theme }) => theme.widths.tablet }) {
    flex-direction: column;
  }

  img {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 55px 80px 90px 0;

    @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
`

export const BecomeDriverContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  margin: 85px 0 110px 0;

  @media(max-width: ${({ theme }) => theme.widths.tablet}) {
      margin-top: 50px;
      margin-bottom: 80px;
    }

  h1 {
    font-size: 32px;
    font-weight: 300;
    color: var(--c-primary);

    strong {
      width: max-content;
      position: relative;
      display: flex;

      font-size: 32px;
      font-weight: 800;
      color: var(--c-primary);

      z-index: 1;

      &::after {
        position: absolute;

        content: '';
        
        height: 8px;
        width: 100%;
        
        bottom: 5px;
        left: 0;
        background: var(--c-alert-primary);

        z-index: -1;
      }
    }
  }

  h2 {
    margin-top: 18px;

    font-size: 16px;
    font-weight: 700;
    color: var(--c-primary);
  }

  p {
    margin-top: 15px;
    margin-bottom: 28px;

    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray-primary);
    letter-spacing: 1px;
    line-height: 180%;
  }
`