import { PickupRegistrationButton } from '../PickupRegistrationButton'
import { RoundedFacebookLinkButton } from '../RoundedFacebookLinkButton'
import { Questions } from '../Questions'

import {
  Container,
  IntroductionContent,
  IntroductionContentButtons,
  IntroductionContentFacebookButton
} from './styles';

import * as S from './styles'

export function IntroductionBox({ onOpenSchedulePickupModal }) {
  return (
    <S.Wrapper>
      <Container>
        <IntroductionContent>
          <h2>You call, we haul</h2>
          <h1>
            Get rid of your<br />
            <strong>Scrap Metal</strong> with<br />
            Grunber
          </h1>
          <p>GRUNBER offers a recycling program<br /> that supports local employment.</p>

          <IntroductionContentButtons>
            <PickupRegistrationButton onOpenSchedulePickupModal={onOpenSchedulePickupModal} />
            <IntroductionContentFacebookButton>
              <RoundedFacebookLinkButton />
              <Questions />
            </IntroductionContentFacebookButton>
          </IntroductionContentButtons>
        </IntroductionContent>
      </Container>
    </S.Wrapper>
  );
}