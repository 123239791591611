import { useState, useEffect } from 'react'
import Modal from 'react-modal' 

import { 
  Header, 
  IntroductionBox,
  StepsBox,
  MaterialsBox,
  ScheduleRecyclingBox,
  CustomerCommentsBox,
  BecomeDriverBox,
  AboutUsBox,
  FinishBox,
  Footer,

  SchedulePickupModal,
  ConfirmationModal
} from './components'

Modal.setAppElement('#root')

export function App() {
  const [openSchedulePickupModal, setOpenSchedulePickupModal] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  
  useEffect(() => {
    new URL(window.location.href).searchParams.get('modal') ===
      'paymentconfirm' && setIsConfirmModalOpen(true)
  }, [])

  function handleOpenSchedulePickupModal() {
    setOpenSchedulePickupModal(true)
  }

  function handleCloseSchedulePickupModal() {
    setOpenSchedulePickupModal(false)
  }

  function handleCloseConfirmationModal() {
    setIsConfirmModalOpen(false)
  }

  return (
    <>
      <Header onOpenSchedulePickupModal={handleOpenSchedulePickupModal} />
      <IntroductionBox onOpenSchedulePickupModal={handleOpenSchedulePickupModal}/>
      <StepsBox />
      <MaterialsBox />
      <ScheduleRecyclingBox onOpenSchedulePickupModal={handleOpenSchedulePickupModal}/>
      <CustomerCommentsBox />
      <BecomeDriverBox />
      <AboutUsBox />
      <FinishBox onOpenSchedulePickupModal={handleOpenSchedulePickupModal}/>
      <Footer />


      <SchedulePickupModal 
        isOpen={openSchedulePickupModal}
        onRequestClose={handleCloseSchedulePickupModal}
      />
      <ConfirmationModal isOpen={isConfirmModalOpen} onRequestClose={handleCloseConfirmationModal}/>
    </>
  );
}