import { ThemeProvider } from 'styled-components'

export const widths = {
  small: '320px',
  mobile: '640px',
  tablet: '768px',
  screen: '1280px',
  largeScreen: '1536px',
}

export default function Provider({ children }) {
  return (
    <ThemeProvider theme={{ widths }}>
      { children }
    </ThemeProvider>
  )
}