import { FaInstagram } from 'react-icons/fa'

import { InstagramButton } from './styles';

export function RoundedInstagramLinkButton() {
  const instagramUrl = "https://www.instagram.com/grunberoficial/"

  return (
    <InstagramButton onClick={() => window.open(instagramUrl, '_blank')}>
      <FaInstagram />
    </InstagramButton>
  );
}