import { SimpleSlider } from '../CommentsSlider'

import * as S from './styles'

export function CustomerCommentsBox() {
  return (
    <S.Wrapper>
      <S.Container>
          <S.CustomerCommentsHeaderContent>
            <h1>
              Discover what customers say about <strong>GRUNBER</strong>
            </h1>
          </S.CustomerCommentsHeaderContent>
          <S.CustomerCommentsContentSlider>
            <SimpleSlider />
          </S.CustomerCommentsContentSlider>
      </S.Container>
    </S.Wrapper>
  )
}
