import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    // Main Colors
    --c-primary: #00352F;
    --c-secondary: #25864F;
    --c-primary-light: #065237;
    --c-secondary-light: #45CB56;


    // Gray Tones
    --c-gray-primary: #819C9B;
    --c-gray-secondary: #DEE7E7;
    --c-gray-primary-light: #B2C2C0;
    --c-gray-secondary-light: #F9FBFA;


    // System Colors
    --c-error-primary: #FF4D4D;
    --c-alert-primary: #FFD24D;
    --c-success-primary: #37C549;
    --c-info-primary: #6BAFD3;
    --c-info-primary-dark: #5E8FF1;
    --c-info-secondary: #CDEFF9;
    --c-info-secondary-light: #F9FEFF;


    // Other Colors
    --c-white: #FFFFFF;
    --c-dark: #21292F;
    --c-disabled: ##BFC2CA;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media(max-width: 1440px) {
      font-size: 93.75%;  // 15px
    }

    @media(max-width: 768px) {
      font-size: 87.5%; // 14px
    }

    @media(max-width: 425px) {
      font-size: 77.5%; // 14px
    }
  }

  body {
    position: relative;
    background: var(--c-white);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, select, button, h1, h2, h3, strong, div, span {
    font: 400 1rem "Inter", sans-serif;
  }

  button {
    cursor: pointer;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 10;
  }

  .react-modal-content {
    position: relative;
    
    width: 90%;
    max-width: max-content;
    height: max-content;

    background: var(--c-white);
    border-radius: 10px;
  }

  .react-modal-close {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;

    width: 50px;
    height: 50px;

    margin: 10px 10px 0 0;

    border: 0;
    border-radius: 5px;
    background: var(--c-gray-secondary);

    transition: background 0.5s, color 0.5s;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: var(--c-primary);
      color: var(--c-white);
    }
  }

  // Remover
  .wrapper-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 32px;

    width: 100%;
    max-width: var(--large-screen);
    margin: 0 auto;
  }

  // Remover
  .box-container {
    @media(max-width: ${({ theme }) => theme.widths.screen}) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  .content-wrapper {
    width: 100%;
    display: flex;  
    align-items: center;

    overflow: hidden;
  }

  .content-container {
    width: 90%;
    max-width: ${({ theme }) => theme.widths.screen};
    margin: auto;
  }

  .tooltip-modal {
    opacity: 1 !important;
    border: 2px solid #dee7e7 !important;
    max-width: 300px;
    width: 80%;

    line-height: 20px;

    @media (max-width: ${({ theme }) => theme.widths.small}) {
      font-size: 2px !important;
      line-height: 15px;
      margin-left: 3px !important;
    }
  }
`