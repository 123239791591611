import { RoundedFacebookLinkButton } from '../RoundedFacebookLinkButton'
import { RoundedInstagramLinkButton } from '../RoundedInstagramLinkButton'

import * as S from './styles'

export function AboutUsBox() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.AboutUsContent>
          <h1>
            About&nbsp;<strong>Us</strong>
          </h1>
          <p>
            We have found that nearly everything we touch can be recycled and
            collected typically non-recyclable items through national,
            first-of-their-kind recycling platforms.
          </p>
          <p>
            With your help, we've diverted millions of pounds of valuable
            resources from landfills all over the world, and we're just getting
            started.
          </p>
          <p>
            Our means of communication is 100% digital, you only inform us the
            quantity and the type of recyclable, the responsibility is ours to
            make the collection without you having to leave the comfort of your
            home.
          </p>
          <div>
            <RoundedFacebookLinkButton />
            <RoundedInstagramLinkButton />
          </div>
        </S.AboutUsContent>
      </S.Container>
    </S.Wrapper>
  )
}
